import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { CircularProgress, withStyles } from '@material-ui/core';
import AnimatedNumber from 'react-animated-numbers';
import drums from '../../../static/decorations/drums.svg';
import Layout from '../../components/layout';
import s from './Home.scss';
import welcome from '../../../content/home/welcome_content.json';
import ideas from '../../../content/home/ideas.json';
// import hero from "../../../content/home/heros.json"
import header from '../../../content/home/header_content.json';
import hero from '../../../static/images/skymill_hero_1/landing_hero_1.svg';
import heroMobile from '../../../static/images/skymill_hero_1/landing_hero_1_mobile.svg';
import background from '../../../static/images/skymill_hero_1/hero-fallback.png';
import Container from '../../components/Container';
import KeyPoints from '../../components/KeyPoints';
import { markdownParser } from '../../utils';
import TrustingFriends from '../../components/modules/Home/TrustingFriends';
import Button from '../../components/Button';
import audition from '../../../content/home/audition_content.json';
import skymillInNumbers from '../../../content/home/skymillInNumbers.json';
import { galleryImages as trustingFriendsImages } from '../../../content/home/trustingFriends.json';
import Separator from '../../components/Separator/Separator';

const headerContent = header.content.find((data) => data?.display);
const welcomeContent = welcome.content.find((data) => data?.display);
const ideasContent = ideas.content.find((data) => data?.display);
const auditionContent = audition.content.find((data) => data?.display);
const { stats } = markdownParser(skymillInNumbers);

export const HomeLayout = () => {
  const [fill, setFill] = useState(false);

  const animate = () => {
    if (!document.getElementById('skymillStats')) return;

    const isInViewport = document.getElementById('skymillStats').offsetTop
      <= window.scrollY + window.innerHeight;

    setFill(isInViewport);
  };

  //  started on rotating hero images
  // const heroObj = {
  //   1: `url(${hero}), url(${background})`,
  //   2: `url(${hero}), url(${background})`,
  // };

  // const likeLiness = Math.floor(Math.random() * (4 - 1 + 1) + 1);

  useEffect(() => {
    animate();

    window.addEventListener('scroll', animate);
  }, []);

  return (
    <div className="Home">
      <div className="banner mobile-only">
        <div
          style={{ backgroundImage: `url(${heroMobile})` }}
          alt="Skymill - Orchestrating your ideas"
        />
        <section>
          <h1 className="title lead">
            <b>{headerContent?.title}</b>
            {headerContent?.subtitle}
          </h1>
          <p className="lead">{markdownParser(headerContent?.body)}</p>
        </section>
      </div>

      <div
        className="banner desktop-only"
        style={{ backgroundImage: `url(${hero}), url(${background})` }}
        // style={{ backgroundImage: likeLiness > 1 ? heroObj[1] : heroObj[2] }}
      >
        <Container>
          <section>
            <h1 className="title lead">
              <b>{headerContent?.title}</b>
              {headerContent?.subtitle}
            </h1>
            <p className="lead">{markdownParser(headerContent?.body)}</p>
          </section>
        </Container>
      </div>
      <Container>
        <section className="welcome">
          <h2 className="title">
            <b>{welcomeContent?.title}</b>
            {welcomeContent?.subtitle}
          </h2>
          <p className="cols">
            <div>{markdownParser(welcomeContent?.bodyCol1)}</div>
            <div>{markdownParser(welcomeContent?.bodyCol2)}</div>
          </p>
        </section>
      </Container>

      <KeyPoints
        title={ideasContent?.title}
        subtitle={ideasContent?.subtitle}
        points={ideasContent?.ideas}
        button={{
          text: ideasContent?.buttonText,
          link: ideasContent?.buttonLink,
        }}
        border
      />
      <Separator id="separator-your-ideas" color="#f6efe9" shape="triangles" />
      <TrustingFriends images={trustingFriendsImages} />
      <section className="bgPrimaryLightest">
        <Container>
          <div className="content">
            <h2 className="title">
              <b>{auditionContent?.title}</b>
              {auditionContent?.subtitle}
            </h2>
            {markdownParser(auditionContent?.body)}
            <div className="buttons">
              <Link to={auditionContent?.buttonLink1}>
                <Button
                  type="button"
                  design="simplePrimary"
                  uppercase
                  text={auditionContent?.buttonText1}
                />
              </Link>
              <a href={auditionContent?.buttonLink2}>
                <Button
                  type="button"
                  design="main"
                  uppercase
                  text={auditionContent?.buttonText2}
                />
              </a>
            </div>
          </div>
          <div className="illustration">
            <img src={drums} alt="" />
          </div>
        </Container>
      </section>
      <section className="bgPrimaryLighter" id="skymillStats">
        <Container flexDirection="flexColumn" justifyCenter>
          <h2 className="title">
            <b>{skymillInNumbers?.title}</b>
            {skymillInNumbers?.subtitle}
          </h2>
          <div className="stats">
            {stats.map((stat, index) => (
              <div className="stat__box" key={`${index + 1}-${stat.color}`}>
                <div className="stat__container">
                  <div className="stat__number">
                    <AnimatedNumber
                      fontStyle={{ fontWeight: 800, fontSize: 45 }}
                      animateToNumber={fill ? stat.number : 0}
                      includeComma
                      config={{ tension: 89, friction: 40 }}
                    />
                  </div>
                  <CircularProgress
                    style={{ color: skymillInNumbers.bgColor }}
                    size={160}
                    thickness={5}
                    variant="determinate"
                    value={100}
                    className="progressBackground"
                  />
                  <CircularProgress
                    style={{ color: stat.color }}
                    size={160}
                    thickness={5}
                    variant="determinate"
                    value={fill ? Number(stat.progressFill) : 0}
                    className="circularProgress"
                  />
                </div>
                <h5 className="stat__text">{stat.title}</h5>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <Separator
        id="separator-skymill-in-numbers"
        color="#d3eaef"
        shape="squares"
      />
    </div>
  );
};

const Home = ({ location }) => (
  <Layout location={location}>
    <HomeLayout />
  </Layout>
);

Home.propTypes = {
  location: PropTypes.shape().isRequired,
};

export default withStyles(s)(Home);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
