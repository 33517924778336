import React, { useMemo } from 'react';
import PropTypes, { string } from 'prop-types';
import { withStyles } from '@material-ui/core';
import trustingFriends from '../../../../../content/home/trustingFriends.json';
import s from './TrustingFriends.scss';
import Container from '../../../Container';
import { shuffle } from '../../../../utils';

const TrustingFriends = ({ images = [] }) => {
  const shuffledImages = useMemo(() => shuffle(images).splice(0, 7), [images]);

  return (
    <section className="TrustingFriends">
      <Container alignCenter flexDirection="flexColumn">
        <h2 className="title">
          <b>{trustingFriends?.title}</b>
          {trustingFriends?.subtitle}
        </h2>
        <div className="images__wrapper">
          {shuffledImages.map((image) => (
            <div key={image} className="box">
              <img className="box__image" src={image} alt="trusting company" />
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

TrustingFriends.propTypes = {
  images: PropTypes.arrayOf(string).isRequired,
};

export default withStyles(s)(TrustingFriends);
