import React from 'react';
import PropTypes from 'prop-types';
import Circles from '../../../static/assets/separator-circles.svg';
import Squares from '../../../static/assets/separator-squares.svg';
import Triangles from '../../../static/assets/separator-triangles.svg';

const Separator = ({ color, shape, id, className }) => {
  let svg;
  if (shape === 'circles') {
    svg = <Circles key={id} style={{ color }} />;
  } else if (shape === 'squares') {
    svg = <Squares key={id} style={{ color }} />;
  } else if (shape === 'triangles') {
    svg = <Triangles key={id} style={{ color }} />;
  }

  return (
    <div className="Separator" key={id}>
      <div className={className}>{svg}</div>
    </div>
  );
};

Separator.defaultProps = {
  color: 'black',
  shape: 'circles',
  id: '',
};

Separator.propTypes = {
  color: PropTypes.string,
  shape: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.object,
};

export default Separator;
