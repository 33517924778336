import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { Link } from 'gatsby'
import Button from '../Button'
import Container from '../Container'
import s from './KeyPoints.scss'
import { markdownParser } from '../../utils'

const KeyPoints = ({ title, subtitle, points, button, border }) => (
  <div className="KeyPoints">
    <section className="bgAccentLighter">
      <Container flexDirection="flexColumn" justifyCenter>
        <h2 className="title">
          <b>{title}</b> {subtitle}
        </h2>
        <div className="icons">
          {points.map(point => (
            <div className="wrapper" key={`point_${Math.random()}`}>
              <div className="icon">
                <img src={point.icon} alt="" />
              </div>
              <h6>{point.title}</h6>
              {point.body && <p>{markdownParser(point.body)}</p>}
            </div>
          ))}
        </div>
        {button.link && button.text && (
          <div className="buttons">
            <Link to={button.link}>
              <Button
                type="button"
                design="accent"
                uppercase
                text={button.text}
              />
            </Link>
          </div>
        )}
      </Container>
      {border && <hr className="spikes" />}
    </section>
  </div>
)

KeyPoints.defaultProps = {
  title: '',
  subtitle: '',
  points: '',
  button: {
    text: '',
    link: '',
  },
  border: false,
}

KeyPoints.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string,
    }),
  ),
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  border: PropTypes.bool,
}

export default withStyles(s)(KeyPoints)
